<template>
  <div v-dragged="onDragged" class="w-8 absolute centred z-30 bg-white shadow pr-4 pl-4 pt-5 pb-6 cursor-move" style="width: 300px">
    <div class="flex w-full justify-between mb-2">
      <div class="w-full">
        <span class="text-lightblue font-semibold" style="width: 80px; display: inline-block"> Объект: </span>
        <span class="text-darkblue font-semibold" style="word-break: break-word !important">
          {{ row.unit_name }}
        </span>
      </div>
      <div class="flex">
        <i class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold ml-3" @click="$emit('close', index)"></i>
      </div>
    </div>
    <div class="flex w-full">
      <span class="text-lightblue font-semibold" style="width: 80px"> Дата: </span>
      <span class="text-darkblue font-semibold">
        {{ row.date_and_time }}
      </span>
    </div>
    <div class="w-full mt-4">
      <img style="border-radius: 7px" :src="imgPath" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number
    }
  },
  components: {},

  data() {
    return {
      basicUrl: ''
    }
  },

  computed: {
    imgPath() {
      return this.basicUrl + this.row._photo_url
    }
  },

  created() {
    this.basicUrl = document.location.href + '/api_v1/'
  },

  methods: {
    onDragged({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (first) {
        this.isDragging = true
        return
      }
      if (last) {
        this.isDragging = false
        return
      }
      let left = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
      let top = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
      el.style.left = left + deltaX + 'px'
      el.style.top = top + deltaY + 'px'
    }
  }
}
</script>

<style lang="stylus" scoped>
.centred {
  position: fixed;
  top: 40%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
